import React from 'react';
import PropTypes from 'prop-types';

import { Navigation, NavigationProvider, MainContainer, ResizeGrid, Footer } from '@ccg/fork';

import { getNextLinkProps } from '../../../helper';

import Seo from '../../shared/Seo/Seo';

const PageTemplate = ({ navigation, footer, seo, seoDefaults, children }) => (
  <NavigationProvider>
    <div className="transition__curtain" />
    <Seo data={seo} defaults={seoDefaults} />

    {navigation && navigation.mainLinks.length > 0 && (
      <Navigation
        homeLink={{ href: '/', asPath: '/' }}
        mainLinks={navigation.mainLinks.map(({ linkTo, id, subLinks }) => ({
          id,
          ...getNextLinkProps(linkTo),
          subLinks: subLinks.map(link => getNextLinkProps(link.linkTo))
        }))}
        subLinks={
          footer &&
          footer.mainLinks &&
          footer.mainLinks.map(({ linkTo, id }) => ({ id, ...getNextLinkProps(linkTo) }))
        }
      />
    )}
    <MainContainer>
      <div className="transition__content">
        {children}
        <Footer
          homeLink={{ href: '/', asPath: '/' }}
          mainLinks={
            navigation.mainLinks && navigation.mainLinks.map(link => getNextLinkProps(link.linkTo))
          }
          links={
            footer &&
            footer.mainLinks &&
            footer.mainLinks.map(link => getNextLinkProps(link.linkTo))
          }
        />
      </div>
    </MainContainer>
    <ResizeGrid />
  </NavigationProvider>
);

PageTemplate.propTypes = {
  navigation: PropTypes.shape().isRequired,
  footer: PropTypes.shape().isRequired,
  seo: PropTypes.shape().isRequired,
  seoDefaults: PropTypes.shape().isRequired,
  children: PropTypes.node
};

PageTemplate.defaultProps = { children: null };

export default PageTemplate;
